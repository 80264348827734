div#info_message_modal {
  .modal-content {
    overflow: hidden;
  }

  .modal-footer {
    padding: 12px 13px 13px;
    margin-top: 0;
  }

  .error_modal {
    .modal-header {
      color: $error-color;
      background-color: $error-background-color;
      border-color: $error-border-color;
    }
  }

  .success_modal {
    .modal-header {
      color: $success-color;
      background-color: $success-background-color;
      border-color: $success-border-color;
    }
  }

  .info_modal {
    .modal-header {
      color: $info-color;
      background-color: $info-background-color;
      border-color: $info-border-color;
    }
  }

  .bs-callout {
    padding: 20px;
    border-left-style: solid;
    border-left-color: #eee;
    border-left-width: 10px;
  }

  .bs-callout-error {
    border-left-color: $error-color;
  }

  .bs-callout-success {
    border-left-color: $success-color;
  }

  .bs-callout-info {
    border-left-color: $info-color;
  }
}

a.fline_info_collapse {
  cursor: pointer;
}

/* Icon when the collapsible content is shown */
a.fline_info_collapse:before {
  font-family: "Glyphicons Halflings";
  content: "\e114";
  float: left;
  margin-right: 15px;
  margin-top: -3px;
}

/* Icon when the collapsible content is hidden */
a.fline_info_collapse.collapsed:before {
  content: "\e080";
}